export const SUCCESS = 200;
export const ERROR = 4000;
export const LOGIN_FAIL = 50012;
export const OTHER_LOGIN_FAIL = 50014;
export const WECHAT_AUTH_ERROR = 4001;
export const NOT_FOUND_ERROR_CODE = 4004;
export const NOT_BIND_ERROR_CODE = 4005;
export const NOT_BIND_USER_ERROR_CODE = 4006;
export const STUDENT_SUCCESS_CODE = 2001;
export const TEACHER_SUCCESS_CODE = 2002;

