import Vue from 'vue'
import VueRouter from 'vue-router'
import Vant from 'vant';

Vue.use(VueRouter)
Vue.use(Vant);

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Login',
    component: () => import('@/views/login/Logout.vue'),
  },
  {
    path: '/noticeteach',
    name: 'noticeteach',
    component: () => import('@/views/Notice/Noticeteach.vue'),
    meta: { needLogin: true }
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/Notice/NoticeDetail.vue'),
    meta: { needLogin: true }
  },
  {
    path: '/publish',
    name: 'publish',
    component: () => import('@/views/Notice/NoticePublish.vue'),
    meta: { needLogin: true }
  },

  {
    path: '/parents',
    name: 'parents',
    component: () => import('@/views/Notice/NoticeParents.vue'),
    meta: { needLogin: true }
  },
  {
    path: '/read',
    name: 'read',
    component: () => import('@/views/Notice/NoticeRead.vue'),
    meta: { needLogin: true }
  },
  {
    path: '/ParentsDetail',
    name: 'ParentsDetail',
    component: () => import('@/views/Notice/NoticeParentsDetail.vue'),
    meta: { needLogin: true }
  },

  {
    path: '/choose',
    name: 'Choose',
    component: () => import('@/views/login/Choose.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/teacher_index',
    name: 'teacher_index',
    component: () => import('../views/teacher/index.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/circle',
    name: 'Circle',
    component: () => import('../views/Class_circle/Circle.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/student_index',
    name: 'student_index',
    component: () => import('@/views/student/index.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/teacher/Detail.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/noactva',
    name: 'Noactva',
    component: () => import('../views/Noactva.vue'),
    meta: { needLogin: true },
  },
  /* {
    path: '/addhead',
    name: 'Addhead',
    component: () => import('../views/Addhead.vue'),
    meta: { needLogin: true },
  }, */
  {
    path: '/classcircle',
    name: 'Classcircle',
    component: () => import('../views/Class_circle/Classcircle.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/classleave',
    name: 'Classleave',
    component: () => import('../views/Leave/Classleave.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/leavecommit',
    name: 'Leavecommit',
    component: () => import('../views/Leave/Leavecommit.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/leavedetail',
    name: 'Leavedetail',
    component: () => import('../views/Leave/Leavedetail.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/send',
    name: 'send',
    component: () => import('../views/Event_release/send.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/teameasure',
    name: 'Teameasure',
    component: () => import('../views/Leave/Teameasure.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/teadeatail',
    name: 'Teadeatail',
    component: () => import('../views/Leave/TeaDeatail.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/teasub',
    name: 'Teasub',
    component: () => import('../views/Leave/TeaSub.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('../views/pay/index.vue'),
    meta: { needLogin: true },
  },
  // 接送人
  {
    path: '/pick_list',
    name: 'pick_list',
    component: () => import('../views/Pickup/list.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/pickup_add',
    name: 'pickup_add',
    component: () => import('../views/Pickup/add.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/s_details',
    name: 's_details',
    component: () => import('../views/Pickup/s_details.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/pickup_details',
    name: 'pickup_details',
    component: () => import('../views/Pickup/details.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import('../views/Topics/Index.vue'),
    meta: { needLogin: true },
  },
  {
    path: '/topics_detail',
    name: 'topics_detail',
    component: () => import('../views/Topics/Detail.vue'),
    meta: { needLogin: true },
  },
]

const router = new VueRouter({
  /* mode: 'history', */
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
