import Cookies from "js-cookie";
import router from "../router";
import {Notify} from "vant";
import {isBind, logout} from "../api/login";
import * as Status from "./status";

const expires = 1

export function setUserInfo(data) {
    localStorage.setItem('userinfo', data)
}

export function setMobile(data) {
    localStorage.setItem('mobile', data)
}

export function getMobile() {
    return localStorage.getItem('mobile');
}

export function getUserInfo() {
    let info = localStorage.getItem('userinfo');
    if (info) {
        info = JSON.parse(info);
    }
    return info;
}

export function getOpenId() {
    let openid = '';
    let info = localStorage.getItem('userinfo');
    if (info) {
        info = JSON.parse(info);
        openid = info['openid'];
    }
    return openid;
}

export function getToken() {
    return localStorage.getItem('token');
}

export function setToken(data) {
    localStorage.setItem('token', data)
}

export function removeToken() {
    localStorage.removeItem('token')
}

export function removeUserInfo() {
    localStorage.removeItem('userinfo')
}

export function removeCurrentUser()
{
    localStorage.removeItem('currentUser')
}

export function removeMobile()
{
    localStorage.removeItem('mobile')
}

export function isLogin() {
    isBindUser();

    // isAuth();
    // let token = getToken();
    // if (token) {
    //     let mobile = getMobile()
    //     if (mobile) {
    //         return true;
    //     } else {
    //         Notify({ type: 'warning', message: '请登录' });
    //         router.push('/');
    //     }
    // } else {
    //     let mobile = getMobile()
    //     if (mobile) {
    //         router.push('/Choose');
    //         // window.location.href = '/#/Choose'
    //     } else {
    //         Notify({ type: 'warning', message: '请登录' });
    //         router.push('/');
    //     }
    // }

}

export function isAuth() {
    let info = getUserInfo();
    if (info) {
        return true;
    } else {
        router.push('/');
    }
}

export function isBindUser()
{
    var info = getUserInfo();
    if (info) {
        isBind({ openid: info['openid']});
    } else {
        router.push('/');
    }
}


export function doLogout() {
    let openId = getOpenId();
    logout({openid: openId}).then((res) => {
        // if (res.code === Status.SUCCESS) {
            localStorage.removeItem('userinfo')
            localStorage.removeItem('token')
            localStorage.removeItem('mobile')
            localStorage.removeItem('currentUser')
            Notify({ type: "success", message: "退出成功", duration: 2000 });
            router.push('/')
        // }
    })
    return true;
}

// 当前用户
export function currentUser()
{
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
    }
    return info;
}

export function setCurrentUser(data) {
    localStorage.setItem('currentUser', data)
}

export function getUserId() {
    let res = '';
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
        res = info['user_id'];
    }
    return res;
}

export function getUserName() {
    let res = '';
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
        res = info['user_name'];
    }
    return res;
}

export function getUserMobile() {
    let res = '';
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
        res = info['mobile'];
    }
    return res;
}

export function getUserPhoto() {
    let res = '';
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
        res = info['photo'];
    }
    return res;
}

export function getUserType() {
    let res = '';
    let info = localStorage.getItem('currentUser');
    if (info) {
        info = JSON.parse(info);
        res = info['user_type'];
    }
    return res;
}

export function setUserIndexPage(page) {
    localStorage.setItem('currentUserIndexPage', page)
}

export function getUserIndexPage() {
    localStorage.getItem('currentUserIndexPage')
}