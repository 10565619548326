<template>
    <!-- <div class="app-main"> -->
  <div class="app-main" :key="$route.fullPath">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created() {
    if (sessionStorage.getItem("store")) {
      this.$store.replaceState(
        Object.assign(
          {},
          this.$store.state,
          JSON.parse(sessionStorage.getItem("store"))
        )
      );
    }
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("store", JSON.stringify(this.$store.state));
    });
  },
};
</script>

<style>
body {
  background-color: #FFFFFF;
}
</style>
