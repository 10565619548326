import request from '@/utils/request'
// 获取验证码
export function GetsmsCode(data) {
  return request({
    url: '/mobile/user/smsCode',
    method: 'post',
    data
  })
}
// 登录
export function Login(data) {
  return request({
    url: '/mobile/user/login',
    method: 'post',
    data
  })
}
// 选择角色登录
export function DoLogin(data) {
  return request({
    url: '/mobile/user/doLogin',
    method: 'post',
    data: data
  })
}
export function auth(query) {
  return request({
    url: '/mobile/user/auth',
    method: 'get',
    params: query
  })
}

export function getUserList(data) {
  return request({
    url: '/mobile/user/getUserList',
    method: 'post',
    data: data
  })
}

export function isBind(query)
{
  return request({
    url: '/mobile/user/isBind',
    method: 'get',
    params: query
  })
}

// 退出登录
export function logout(data) {
  return request({
    url: '/mobile/user/logout',
    method: 'post',
    data
  })
}

// 日志
export function log(data) {
  return request({
    url: '/mobile/user/log',
    method: 'post',
    data
  })
}