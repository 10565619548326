import axios from "axios";//原生的axios
import Cookies from "js-cookie";
import * as Status from "../utils/status"
import {Notify} from "vant";
import router from "../router";
import {getMobile, getOpenId, getToken, removeCurrentUser, removeMobile, removeToken, removeUserInfo} from "./user";
import {OTHER_LOGIN_FAIL} from "../utils/status";

//用来拦截用的
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
//创建一个单例
const http= axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout:10000,//响应时间
  // headers:{"Content-Type":"application/json;charset=utf-8"},
})
 
//拦截器  -请求拦截
http.interceptors.request.use(config=>{
  // console.log(localStorage.getItem('token'))
  //部分接口需要token
  // let token=localStorage.getItem('token');
  let token = getToken();
  let mobile = getMobile();
  let openid = getOpenId();
  if(token){
    config.headers ={
      'X-token':token,
      'X-Mobile': mobile,
      'X-Openid': openid,
      transformRequest: [data => data]
    }
  }
  return config;
},err=>{
  return Promise.reject(err)
})
 
//拦截器  -响应拦截
http.interceptors.response.use(res=>{
  if(res.data.code===Status.SUCCESS){
    return res.data
  } else if (res.data.code === Status.NOT_BIND_ERROR_CODE) {
    Notify({ type: 'warning', message: '请绑定手机号' });
    router.push('/')
    return res.data

  } else if (res.data.code === Status.NOT_BIND_USER_ERROR_CODE) {
    Notify({ type: 'warning', message: '请绑定用户' });
    router.push('/Choose')
    return res.data

  } else if(res.data.code === Status.LOGIN_FAIL) {
    Notify({ type: 'warning', message: '请选择用户登录' });
    removeToken()
    router.push('/Choose')
    return res.data

  } else if(res.data.code === Status.OTHER_LOGIN_FAIL) {
    Notify({ type: 'warning', message: '当前用户已经在其他设备登录' });
    removeUserInfo()
    removeToken()
    removeMobile()
    removeCurrentUser()
    router.push('/')
    return res.data

  } else if(res.data.code === Status.STUDENT_SUCCESS_CODE) {
    router.push('/student_index')
    return res.data

  } else if(res.data.code === Status.TEACHER_SUCCESS_CODE) {
    router.push('/teacher_index')
    return res.data

  }  else if (res.data.code === Status.NOT_FOUND_ERROR_CODE) {
    Notify({ type: 'warning', message: '记录不存在' });
    return res.data

  } else if (res.data.code === Status.ERROR) {
    Notify({ type: 'warning', message: res.data.msg });
    return res.data

  } else{
    return res.data
    // return Promise.reject(res.data)
  }
},err=>{
  if (err.data?.code === Status.WECHAT_AUTH_ERROR) {
    Notify({ type: 'warning', message: '微信授权失败' });
    // router.push('/')
    return Promise.reject(err)
  }
  Notify({ type: 'warning', message: '网络异常' });
  return Promise.reject(err)
});
 
//整体导出
export default http;
 