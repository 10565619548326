import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vue-photo-preview/dist/skin.css'
import axios from 'axios'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Notify } from 'vant'
import Cookies from "js-cookie";
import api from './api/compress.js'
import'../src/assets/css/iconfont.css'
import * as Status from "./utils/status";
import * as VConsole from "vconsole";
Vue.prototype.$api = api
Vue.prototype.$cookies = Cookies
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.use(Vant);
router.beforeEach(function(to, from, next) {

  let href = window.location.href;
  // console.log("998. href :" + href);
  // store.commit('setLog', "998. href :" + href)

  if (href.includes("me/?code")) {  //url包括 com/?code 证明为从微信跳转回来的
    //vue自动在末尾加了 #/ 符号，截取去掉
    var url = href.substring(0, href.length - 2);
    var jingPosit = url.indexOf("?code"); //获取?code位置
    var urlLeft = url.substring(0, jingPosit);//url左侧部分
    var urlRight = url.substring(jingPosit, url.length); //url右侧部分
    // console.log("999. 重定向 :" + urlLeft + "#/" + urlRight);
    // store.commit('setLog', "999. 重定向 :" + urlLeft + "#/" + urlRight)

    var urlSearch = location.search;
    var theRequest = {};
    if (urlSearch.indexOf("?") !== -1) {
      var str = urlSearch.substr(1);
      var strs = str.split("&");
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
      }
    }

    // console.log(theRequest.code);
    // console.log(theRequest.state);
    let params = '?code=' + theRequest.code + '&state=' + theRequest.state;
    // store.commit('setLog', "1000. 重定向参数 code: " + theRequest.code + "&state: " + theRequest.state)
    // store.commit('setLog', "1001. 重定向左侧 urlLeft: " + urlLeft)

    window.location.href = urlLeft + "#/" + params;//拼接跳转

  }
  if (to.meta.needLogin) {
    //页面是否登录
    if (localStorage.getItem('userinfo')) {
      //本地存储中是否有token(uid)数据
      next()
    } else {

      Notify({ type: 'warning', message: '请授权登录！' });
      //next可以传递一个路由对象作为参数 表示需要跳转到的页面
      next({
        path: "/"
      });
    }

  } else {
    //表示不需要登录
    next(); //继续往后走
  }
});

if (process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole()
  Vue.use(vConsole);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
