import Vue from 'vue'
import Vuex from 'vuex'
import {log} from "../api/login";

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    name: '',
    id:'',
    mobile:'',
    photo: '',
    user_type: '',
  }
}



export default new Vuex.Store({
  state: {
    role:[],
    name: '',
    id:'',
    mobile:'',
    photo: '',
    user_type: '',
    code: '',
    log: []
  },
  mutations: {
    savePath(state,data){
      state.role = data;
    },
    getid(state,id){
      state.id=id;
    },
    getname(state,name){
      state.name=name;
    },
    getmobile(state,mobile){
      state.mobile = mobile
    },
    getphoto(state,photo){
      state.photo = photo
    },
    user_type(state,type){
      state.user_type = type
    },

    RESET_STATE: (state) => {
      Object.assign(state, getDefaultState())
    },
    setCode(state, code) {
      state.code = code
    },
    setLog(state, data) {
      // state.log.push(data)
      // var a = [];
      // let r = localStorage.getItem('log_storage');
      // if (r === null) {
      //   localStorage.setItem('log_storage', JSON.stringify(a));
      // } else {
      //   a = JSON.parse(localStorage.getItem('log_storage'));
      //   a.push(data);
      //   localStorage.setItem('log_storage', JSON.stringify(a));
      // }

      // log({data: data}).then((r) => {
        // console.log(r)
      // })

    }
  },
  actions: {
  },
  modules: {
  }
})
